import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './infrastructure/Routes/Routes';
import "./index.css"
import { Provider } from 'react-redux';
import Store from './state management/Redux/Store/store';
import { AccountProvider } from './pages/account';
import {PersistGate } from 'redux-persist/integration/react'
import { persistStore } from "redux-persist";
import { ToastContainer } from 'react-toastify';
let persistor = persistStore(Store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
{/* <Layout/> */}
<Provider store={Store}>

<PersistGate loading={null} persistor={persistor} >
<BrowserRouter>
<AccountProvider>
<AppRoutes/>
<ToastContainer theme="dark" position="bottom-left" />
</AccountProvider>
</BrowserRouter>
</PersistGate>
  
</Provider>
  </React.StrictMode>
);


