import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { colorTheme } from "../../../infrastructure/Theme/color";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import FlexibleDiv from "../../primaryComponents/flexibleDiv/flexibleDiv.component";
import {
  HeaderText,
  Text
} from "../../primaryComponents/typography/typography.component";
import "../../primaryComponents/carousel/style.css";
import "./style.css";
import EventCard from "../eventCard";
import { useSelector } from "react-redux";

const EventRow = ({ catergoryName, events }) => {
  const eventList = useSelector(({ events }) => events.event_list);
  const darkMode = useSelector(
    (state) => state.persistedReducer.theme.darkmode
  );
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 2
        }
      }
    ]
  };
  return (
    <>
      <FlexibleDiv
        width={"96%"}
        alignItems={"flex-start"}
        margin={"0px 0px 0px 0px"}
        direction={"column"}
        breakPoint={'780px'}
        breakPointwidth={'100%'}
      >
        {/* Head */}
        <FlexibleDiv>
          {/* Title */}
          <FlexibleDiv
            width={"100%"}
            justifyContent={"flex-start"}
            breakPoint={"680px"}
            breakPointwidth={"100%"}
            breakPointPadding={'0px 0px 0px 23px'}
          >
            <HeaderText
              fontSize={fontSizes.desktop_subtitle}
              resFontSize={"14.5px"}
              color={darkMode ? colorTheme.white.white : "#000"}
            >
              Events
            </HeaderText>
          </FlexibleDiv>
          {/* See all */}
          {/* <FlexibleDiv
            width={"20%"}
            justifyContent={"flex-end"}
            breakPoint={"680px"}
            breakPointwidth={"50%"}
          >
            <Text
              color={colorTheme.primary_color}
              fontWeight={"500"}
              cursor={"pointer"}
              marginRight={"30px"}
              fontSize={"0.9rem"}
              resFontSize={"0.74rem"}
            >
              See all
            </Text>
          </FlexibleDiv> */}
        </FlexibleDiv>

        {/* End Of Head */}
        {/* 
        <div className="event-row-carousel">
          <Slider {...settings}>
            {eventList?.events?.map(
              ({ cover_image, title, startDate, id }, index) => {
                const event_id = id
                return (
                  <EventCard
                  link={`/event-details/${id}/${encodeURIComponent(title)}`}
                    key={index}
                    cover_image={cover_image}
                    event_id={id}
                    title={title}
                    startDate={startDate}
                  />
                );
              }
            )}
          </Slider>
        </div> */}
        <FlexibleDiv
          // margin={"30px 0px"}
          gap={"23px"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointJustifyContent={"flex-start"}
          breakPointPadding={'0px 23px'}
          alignItems={"center"}
          flexWrap={"no-wrap"}
        >
          {eventList?.events?.map(
            ({ cover_image, title, startDate, id, tickets,location,category,user }, index) => {
              return (
                <EventCard
                  style={{ overflowX: "hidden" }}
                  location={location}
                  breakPointwidth={eventList?.length === 1 ? "80%" : "100%"}
                  breakPoint={"780px"}
                  width={"30%"}
                  link={`/event-details/${id}/${encodeURIComponent(title)}`}
                  key={index}
                  organizer={user}
                  cover_image={cover_image}
                  event_id={id}
                  category={JSON.parse(category)}
                  title={title}
                  startDate={startDate}
                  tickets={tickets}
                />
              );
            }
          )}
        </FlexibleDiv>
      </FlexibleDiv>
    </>
  );
};

export default EventRow;
