import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router";
import FlexibleDiv from "../../../components/primaryComponents/flexibleDiv/flexibleDiv.component";
import { Image } from "../../../components/primaryComponents/image/image";
import no_user from "../../../assets/images/no_user.png";
import { Text } from "../../../components/primaryComponents/typography/typography.component";
import { fontSizes } from "../../../infrastructure/Theme/fontSize";
import { colorTheme } from "../../../infrastructure/Theme/color";
import Button from "../../../components/primaryComponents/button/button.component";
import {
  ShareIcon,
  WatchlistIcon,
} from "../../../components/primaryComponents/icons/icons";
import { IoIosAdd } from "react-icons/io";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { dateDateFormatter } from "../../../utilities/functions/formatDate";
import Skeleton from "react-loading-skeleton";
// import Share_Modal from "./Share/share_modal.component";
import { followOrganizer } from "../../../services/follow_organizer";
import { unfollowOrganizer } from "../../../services/unfollow_organizer";
import { setFollowing } from "../../../state management/Redux/slices/events/get_event_details_slice";
import { CONSTANTS } from "../../../utilities/constants";
import { useNavigate, useParams } from "react-router-dom";
// import { publishTheCalenderEvent } from "../../../services/google_services";
export const OrganizerEventDetailsStyle = styled.div`
  position: absolute;
  top: 75%;
  border-radius: 10px;
  z-index: 1;
  margin: auto;
  background-color: ${({ bg }) => (bg ? bg : colorTheme.white.white)};
  width: 50%;
  padding: 20px;
  box-shadow: ${({ shadow }) =>
    shadow ? shadow : "0px 2px 1.5px 1px whitesmoke"};
  @media screen and (max-width: 990px) {
    width: 70%;
  }
  @media screen and (max-width: 880px) {
    width: 80%;
  }
  @media screen and (max-width: 780px) {
    position: relative;
    width: 100%;
    padding: 12px 10px;
    border-radius: 0px;
  }
`;

const OrganizerEventDetailsBox = ({ setShowTickets, setShareModal }) => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const darkMode = useSelector(
    ({ persistedReducer }) => persistedReducer.theme.darkmode
  );

  const navigate = useNavigate();
  const startDate = useSelector(
    ({ event_details }) => event_details.data?.event?.startDate
  );

  const { formattedDate } = dateDateFormatter(startDate).first;
  const tickets = useSelector(
    ({ event_details }) => event_details.data?.event?.tickets
  );

  const userInfo = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.userInfo
  );
  const title = useSelector(
    ({ event_details }) => event_details?.data?.event?.title
  );
  const token = useSelector(
    ({ persistedReducer }) => persistedReducer?.user?.token
  );

  const isFollowing = useSelector(
    ({ event_details }) => event_details.isFollowing
  );
  const organizer = useSelector(
    ({ event_details }) => event_details.data?.organizer
  );
  const loading = useSelector(({ event_details }) => event_details?.loading);
  const subsribeHandler = () => {
    if (!token) {
      alert("Attend says Sign-Up or Login to follow to organizer");
    } else {
      if (isFollowing) {
        dispatch(
          unfollowOrganizer({
            organizerId: organizer?.id,
          })
        );
        dispatch(setFollowing(false));
      } else {
        dispatch(
          followOrganizer({
            organizerId: organizer?.id,
          })
        );
        dispatch(setFollowing(true));
      }
    }
  };

  // const calendarEvent = {
  //   summary: event?.title,
  //   description: event?.description,
  //   start: {
  //     dateTime: startDate,
  //     timezone: "America/Los_Angeles"
  //   },
  //   end: {
  //     dateTime: event?.endDate,
  //     timezone: "America/Los_Angeles"
  //   },
  //   attendees: [{ email: userEmail }],
  //   reminders: {
  //     useDefault: false,
  //     overrides: [
  //       { method: "email", minutes: 24 * 60 },
  //       { method: "popup", minutes: 10 }
  //     ]
  //   }
  // };
  const organizerUserName = organizer?.username;

  return (
    <OrganizerEventDetailsStyle
      bg={darkMode ? colorTheme.black.fadeMini : colorTheme.white.white}
      shadow={darkMode ? "0px 0px 18px rgba(0, 0, 0, 0.1)" : ""}
    >
      <FlexibleDiv alignItems={"flex-start"}>
        {/* OrganizerImage */}
        <FlexibleDiv
          width={"10%"}
          justifyContent={"flex-start"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          breakPointPadding={"12px 12px 0px 12px"}
        >
          <Image
            width={"50px"}
            height={"50px"}
            radius={"50%"}
            src={
              organizer?.profile_image
                ? `${CONSTANTS.profileImageUrl}${organizer?.profile_image}`
                : no_user
            }
            alt="organizer-img"
          />
        </FlexibleDiv>
        {/* Info */}
        <FlexibleDiv
          width={"90%"}
          direction={"column"}
          alignItems={"flex-start"}
          breakPoint={"780px"}
          breakPointwidth={"100%"}
          breakPointPadding={"12px"}
        >
          {/* Title */}
          <FlexibleDiv justifyContent={"flex-start"}>
            <Text
              fontSize={fontSizes.mobile_smallest}
              color={darkMode ? "silver" : colorTheme.ash}
              margin={"0px"}
              resFontSize={"small"}
            >
              Event Organizer
            </Text>
          </FlexibleDiv>
          {/* Organizer Name and Button */}
          <FlexibleDiv justifyContent={"flex-start"} gap={"22px"}>
            {loading ? (
              <Skeleton width={100} height={15} />
            ) : (
              <Text
                fontSize={fontSizes.mobile_body_text}
                color={
                  darkMode ? colorTheme.white.white : colorTheme.black.strong
                }
                fontWeight={"600"}
                margin={"5px 0px"}
              >
                {organizer?.username}
              </Text>
            )}
            {organizerUserName !== userInfo?.username && (
              <Button
                onClick={() => {
                  subsribeHandler();
                }}
                background={isFollowing ? "transparent" : colorTheme.blue}
                color={"white"}
                border={isFollowing ? `0.3px solid ${colorTheme.blue}` : "none"}
                hoverColor={isFollowing ? "none" : "white"}
                hoverBackground={isFollowing ? "transparent" : colorTheme.blue}
                hoverBorder={
                  isFollowing
                    ? `0.3px solid ${colorTheme.blue}`
                    : "0.9px solid skyblue"
                }
                width={"90px"}
                radius={"5px"}
                padding={"8px"}
              >
                {isFollowing ? "Following" : "Follow"}
              </Button>
            )}
          </FlexibleDiv>
          {/* Followers */}
          {/* <FlexibleDiv justifyContent={"flex-start"}>
            <Text
              fontSize={fontSizes.mobile_smallest}
              color={darkMode ? colorTheme.white.white : colorTheme.black.soft}
              fontWeight={"600"}
              resFontSize={"small"}
              margin={"0px"}
            >
              {"1671"} Followers
            </Text>
          </FlexibleDiv> */}

          {/* Share and Date */}
          <FlexibleDiv padding={"12px 0px 12px 0px"}>
            {/* Date */}
            <FlexibleDiv
              width={"50%"}
              justifyContent={"flex-start"}
              breakPoint={"780px"}
              breakPointwidth={"100%"}
            >
              <Text
                fontSize={fontSizes.mobile_smallest}
                color={colorTheme.black.soft}
                fontWeight={"600"}
                resFontSize={"small"}
                margin={"0px"}
              >
                {formattedDate}
              </Text>
            </FlexibleDiv>

            {/* Share and Fav */}
            <FlexibleDiv
              width={"50%"}
              justifyContent={"flex-end"}
              gap={"22px"}
              breakPoint={"780px"}
              breakPointwidth={"100%"}
              breakPointJustifyContent={"flex-start"}
              breakPointPadding={"10px 0px"}
            >
              <ShareIcon
                onClick={() => {
                  setShareModal(true);
                }}
                style={{ cursor: "pointer" }}
                size={fontSizes.mobile_details}
                color={colorTheme.black.soft}
              />
              <Text
                onClick={() => {
                  setShareModal(true);
                }}
                cursor={"pointer"}
                fontSize={fontSizes.mobile_smallest}
                color={colorTheme.black.soft}
                fontWeight={"600"}
                resFontSize={"small"}
                margin={"0px"}
              >
                Share
              </Text>
              {/* <WatchlistIcon
                color={colorTheme.primary_color}
                size={fontSizes.mobile_details}
              /> */}
            </FlexibleDiv>
          </FlexibleDiv>

          {/* Add to calender and ticket buttons */}
          <FlexibleDiv justifyContent={"space-between"}>
            {tickets?.length > 0 ? (
              <Button
                onClick={() => {
                  if (token) {
                    navigate(`/event-details/${eventId}/${title}/tickets`);
                  } else {
                    alert(
                      "Attend says Sign-Up or Login to buy tickets and get notified in your email"
                    );
                  }
                }}
                width={"40%"}
                padding={"7px"}
                radius={"5px"}
                hoverColor={colorTheme.primary_color}
                hoverBorder={`0.9px solid ${colorTheme.primary_color}`}
              >
                Buy Tickets
              </Button>
            ) : // <Button
            // onClick={()=>{
            //   publishTheCalenderEvent(calendarEvent)
            // }}
            //   width={"40%"}
            //   padding={"5px"}
            //   background={darkMode ? colorTheme.white.white : "#045adb24"}
            //   color={"#3090e3"}
            //   radius={"5px"}
            //   hoverColor={"#3090e3"}
            //   hoverBorder={"0.9px solid #3090e3"}
            // >
            //   <IoIosAdd
            //     size={"22px"}
            //     fill="#3090e3"
            //     style={{ marginRight: "8px", color: "red" }}
            //   />{" "}
            //   Add to calender
            // </Button>
            null}
          </FlexibleDiv>
        </FlexibleDiv>
      </FlexibleDiv>
    </OrganizerEventDetailsStyle>
  );
};

export default OrganizerEventDetailsBox;
